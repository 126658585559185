/*
 * @license
 * Copyright 2017 Google LLC All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const config = {
  apiKey: "AIzaSyDaMycFqrY23R29y01U7ztnQBWEjdhbg3M",
  authDomain: "nhienlam-gcip.firebaseapp.com",
  databaseURL: "https://nhienlam-gcip-default-rtdb.firebaseio.com",
  projectId: "nhienlam-gcip",
  storageBucket: "nhienlam-gcip.appspot.com",
  messagingSenderId: "1002639799680",
  appId: "1:1002639799680:web:e9afb5794e22757ebe7d65"
};
